import React from "react"
import JSONData from "branding/site-metadata.json"

export default function Footer() {
    return (
        <footer className="footer">
            <div className = 'br'>
            <div className = 'footerContainer' target="_blank" style = {{background: JSONData.footerColor}}>
                <a href="/terms-of-service" target="_blank" style = {{color: JSONData.footerTextColor}}>Terms of Service  |  </a>
                <a href="/privacy-policy" target="_blank" style = {{color: JSONData.footerTextColor}}>Privacy Policy |  </a>
                <a href="/contact-us" target="_blank" style = {{color: JSONData.footerTextColor}}>Contact |  </a>
                <a href="/uninstall" target="_blank" style = {{color: JSONData.footerTextColor}}>Uninstall |  </a>
                <a href="/unsubscribe" target="_blank" style = {{color: JSONData.footerTextColor}}>Unsubscribe |  </a>
                <a href="/privacy-policy/#cali-consumer" target="_blank" style = {{color: JSONData.footerTextColor}}>Do Not Sell My Personal Information</a>
                </div>
            </div>
        </footer>
    )
}
