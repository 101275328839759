import React from "react"
import JSONData from "src/../branding/site-metadata.json"
import Styles from "./css/amotyp.module.scss"
import {isChrome,isFirefox} from "../lpscripts/browserdetect"
import Footerlinks from "src/components/footerlinks/footer"


class Amotyp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      display: 'block',
      pageData: props.data,
      data: JSONData
    }
    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(this.state.pageData){
      this.state.pageData.then(data => {
        const instanceData = Object.assign(JSONData, data);
        this.setState({ data: instanceData});
        if(isChrome()){
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({data: browserData});
        }
        if(isFirefox()){
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({data: browserData});
        }
      });
    }
  }

  render() {
    return (
      <>
      <div className={Styles.amoContainer}>
      <div className={Styles.stepA}>
               <img className={Styles.aUp} src="/assets/arrow-up-1.png" />
               <div className={Styles.aCard}>
                   <img className={Styles.aBar} src="/assets/bar.png" />
                   <img src={JSONData.headerico} id={Styles.ico}/>
                   <p>Click on the extension icon at any time to use {this.state.data.headerTitle}.</p>
               </div>
           </div>


           <p id={Styles.imsg}>
   			<img src={JSONData.headerLogo} id={Styles.logo}/>
               <span id={Styles.installMsg}>Thank you for installing</span>
               <span> {this.state.data.headerTitle} </span>
           </p>

<Footerlinks></Footerlinks>


</div>
</>

    )
  }
}

export default Amotyp
